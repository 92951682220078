.headerBottomArea {
    background-color: #0a0f34;
}

.headerBottomArea nav {
    display: flex;
    justify-content: space-between;
}


.headerBottomArea nav ul {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    text-align: center;
    align-items: center;
}


.headerBottomArea nav ul a {
    color: white;
    transition: .3s;
    padding: 5px;

}

.headerBottomArea nav ul a:hover {
    color: #c0b596;
}

.custom-navbar {
    background-color: #0a0f34;
}

.custom-font-color {
    color: #fff !important;
}

.custom-toggler button {
    background-color: #fff !important;
}

button.navbar-toggler {
    background-color: #fff !important;
}

nav.navbar.navbar-expand-lg.navbar-light {
    padding-left: 0;
    padding-right: 0;
}

div.container {
    padding: 0;
}



@media only screen and (max-width: 600px) {
    div.container {
        padding: 0 15px;
    }

    div.dropdown-menu.show {
        max-width: 100%;
    }
}