.headerTopMainWrapper {
    background: #0a0f34;
    padding: 10px;
}

.headertop {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headertop ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.headertop ul li {
    display: inline-block;
    margin-right: 20px;
    color: #fff;
}

.headertop ul li i {
    margin-right: 5px;
}

button {
    cursor: pointer;
    background-color: #c0b596;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    padding: 10px 38px;
    border: 2px solid transparent;
    display: inline-block;
    margin-top: 3px;
}

/* Responsive Styles */
@media only screen and (max-width: 768px) {
    .headertop {
        flex-direction: column;
        text-align: center;
    }

    .headertop ul li {
        margin-bottom: 10px;
    }
}