.bookingForm {
  .formInput {
    margin-bottom: 30px;

    label {
      display: block;
      margin-bottom: 5px;
    }

    input,
    select {
      width: 100%;
      height: 50px;
      padding: 6px 15px;
      color: rgb(0, 0, 0);
      background: rgba(192, 181, 150, .2);
      border: 1px solid rgba(192, 181, 150, .5);
      border-radius: 5px;
      outline: none;
      transition: border-color 0.3s ease;

      &:focus {
        border-color: #c0b596;
      }

      &::placeholder {
        color: #bbb5a5;
        font-size: 14px;
      }
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    p {
      text-transform: uppercase;
      font-size: 12px;
      color: #c0b596;
      margin-top: 10px;
    }

    button {
      font-size: 15px;
      font-weight: 700;
      color: #fff;
      padding: 9px 25px;
      border: 2px solid transparent;
      display: inline-block;
      transition: all .3s;
      background: #c0b596;

      &:hover {
        background: #d4c291;
      }
    }
  }

  .time-slots {
    display: flex;
    flex-wrap: wrap;

    .time-slot-button {
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 10px 15px;
      background-color: #c0b596;
      color: #fff;
      border: none;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #d4c291;
      }
    }

    .time-slot-button.active {
      background-color: #d4c291;
    }

  }
}

@media only screen and (max-width: 768px) {
  .formInput {
    width: 100%;
  }
}